import * as React from 'react';
import { styled } from 'styled-components';

export const MinisteroBadge = () => <Chip>Ministero dell&apos;Istruzione</Chip>;

const Chip = styled.small`
  border-radius: 0.5rem;
  background-color: var(--primary-container);
  font-size: var(--font-size-extra-small);
  padding: 0 var(--space-2);
  color: var(--primary);
`;

import { apiGet, apiPost, buildQueryString } from '.';
import { useStudentId } from '../context/app.context';
import { useAsync } from '../hooks/useAsync';
import { ApiListResponse, News, NewsQueryParams } from '../models';

export function getNews(
  studentId: number,
  params: NewsQueryParams,
): Promise<ApiListResponse<News>> {
  return apiGet(
    `/alunno/${studentId}/help/news` +
      `${asQuery(
        params,
      )}&fields=id,titolo,sottotitolo,isComunicazioneMinisteriale,contenuto,dataPubblicazione,dataComunicazione&metadata=count`,
  );
}
export function useFetchUnreadNews(
  params: Omit<NewsQueryParams, 'estraiSoloDaLeggere'>,
) {
  const studentId = useStudentId();

  const [response] = useAsync<ApiListResponse<News>>(() =>
    getNews(studentId, { ...params, estraiSoloDaLeggere: true }),
  );

  return response;
}

export function useLeggiNews(): (idNews: string) => Promise<void> {
  const studentId = useStudentId();
  return (idNews: string) =>
    apiPost(`/alunno/${studentId}/help/news/segna-letta?id=${idNews}`);
}

function asQuery(params: NewsQueryParams): string {
  const { search, ...otherParams } = params;
  const parsedParams = search && Boolean(search) ? params : otherParams;
  return `?${buildQueryString(parsedParams)}`;
}

import * as React from 'react';
import { useFetchUnreadNews, useLeggiNews } from '../../api/news';
import { NewsDialog } from './components/NewsDialog';
import { useSnackbarContext } from '../../context/snackbar.context';

export function UnreadNewsDialog() {
  const response = useFetchUnreadNews({
    offset: 0,
    limit: 50,
  });
  const isError = response.status === 'error';
  const isLoading = response.status === 'loading';
  const isSuccess = response.status === 'success';
  const isIdle = response.status === 'idle';
  const [newsIndex, setNewsIndex] = React.useState(0);
  const [isDialogOpen, setIsDialogOpen] = React.useState(true);
  const leggiNews = useLeggiNews();
  const [, setMessage] = useSnackbarContext();
  const allRead = isSuccess && response.value.data.length === 0;
  React.useEffect(() => {
    if (isError) {
      setMessage(`Impossibile caricare le news in evidenza`);
    }
  }, [isError, setMessage]);
  if (allRead || isLoading || isError || isIdle) {
    return;
  }

  const { data: news, count: newsCount } = response.value;
  const nextNews = () => {
    if (newsIndex < news.length - 1) {
      setNewsIndex(s => s + 1);
    } else {
      setIsDialogOpen(false);
    }
  };
  const makeRead = () => {
    const { id, titolo } = news[newsIndex];
    leggiNews(id).then(() => {
      setMessage(`News "${titolo}" segnata come letta`);
    });
    nextNews();
  };

  return (
    <NewsDialog
      news={news[newsIndex]}
      isOpen={isDialogOpen}
      onDismiss={makeRead}
      newsCounter={`${newsIndex + 1} di ${newsCount}`}
    />
  );
}

import { styled, css } from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import * as CSS from 'csstype';

type FlexProps = {
  $center?: boolean;
  $flexWrap?: CSS.Property.FlexWrap;
  $justifyContent?: CSS.Property.JustifyContent;
  $alignItems?: CSS.Property.AlignItems;
  $flex?: CSS.Property.Flex;
  $gap?: CSS.Property.Gap;
};

export const Flex = styled.div<FlexProps>`
  display: flex;

  ${props =>
    props.$center &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${props =>
    props.$flexWrap &&
    css`
      flex-wrap: ${props.$flexWrap};
    `}

  ${props =>
    props.$justifyContent &&
    css`
      justify-content: ${props.$justifyContent};
    `}

  ${props =>
    props.$alignItems &&
    css`
      align-items: ${props.$alignItems};
    `}

  ${props =>
    props.$flex !== undefined &&
    css`
      flex: ${props.$flex};
    `}

  ${props =>
    props.$gap !== undefined &&
    css`
      gap: ${props.$gap};
    `}
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const Row = styled(Flex)`
  flex-direction: row;
`;

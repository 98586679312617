import * as React from 'react';
import Button from '../../../components/buttons/Button';
import Dialog from '../../../components/dialog';
import { Column, Row } from '../../../components/layout/flexContainers';
import { News } from '../../../models';
import { formatDate, parseDayString } from '../../../shared-logic/date';
import { MinisteroBadge } from './MinisteroBadge';
import { NewsDialogContent } from './NewsDialogContent';

type NewsDialogProps = {
  news: News;
  isOpen: boolean;
  onDismiss: () => void;
  newsCounter?: string;
};
export function NewsDialog({
  news,
  isOpen,
  newsCounter,
  onDismiss,
}: NewsDialogProps) {
  const {
    titolo,
    sottotitolo,
    contenuto,
    dataComunicazione,
    dataPubblicazione,
    isComunicazioneMinisteriale,
  } = news;
  const newsDate = dataComunicazione || dataPubblicazione;
  const formattedDate = formatDate(parseDayString(newsDate), 'dd/MM/yyyy');
  return (
    <Dialog open={isOpen} aria-labelledby="news-modal">
      <Row $justifyContent="space-between" $alignItems="start">
        <Column>
          <h1>{titolo}</h1>
          <strong>{sottotitolo}</strong>
          <Row $gap="var(--space-2)" $alignItems="center">
            <span>{formattedDate}</span>
            {isComunicazioneMinisteriale && <MinisteroBadge />}
          </Row>
        </Column>
        {!!newsCounter && newsCounter}
      </Row>
      <NewsDialogContent dangerouslySetInnerHTML={{ __html: contenuto }} />
      <Row $justifyContent="flex-end">
        <Button onClick={onDismiss}>Ho letto</Button>
      </Row>
    </Dialog>
  );
}
